<!-- Start Footer Area -->
<footer class="footer-area ptb-100 pb-0 bg-image">

    <!-- Start App Download Area -->
<section class="app-download ptb-100 bg-gray">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6">
                <div class="section-title">
                    <h2>Prêt à faire un vrai changement? Construisons ensemble votre prochain projet!</h2>
                    <div class="bar"></div>
                    <p></p>
                    <div class="download-btn">
                        <a routerLink="/contact-us" class="text_btn" style="font-size: 20px;font-weight: bold;">
                            <i class="icofont-ui-check"></i>Construisons quelque chose ensemble</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="download-image">
                    <img src="assets/img/agilisys/a/dede.png" alt="construire des logiciels">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End App Download Area -->


<!-- End Feedback Area -->

<!-- Start Get Started Area -->
<!-- <section class="get-started ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Get Started Free</h2>
            <div class="bar"></div>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
        </div>

        <div class="get-started-form">
            <form>
                <div class="form-group">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Name"
                    />
                </div>

                <div class="form-group">
                    <input
                        type="email"
                        class="form-control"
                        placeholder="Email"
                    />
                </div>

                <div class="form-group">
                    <input
                        type="password"
                        class="form-control"
                        placeholder="Password"
                    />
                </div>

                <button type="submit" class="btn btn-primary">Sign Up</button>
                <p>or use your <a href="#">Facebook Account</a></p>
            </form>
        </div>
    </div>
</section> -->
<!-- End Get Started Area 

<!-- Start Platform Connect -->
<section class="platform-connect ptb-100">
    <div class="container" style="align-items: center;">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <h2>Suivez Nous </h2>
            </div>
            <div class="col-lg-4 col-md-6">
                <a href="https://www.linkedin.com/company/agilisys.ma/" class="box" target="_blank">
                    <img src="assets/img/linkedin-icon.png" style="height: 48px;" alt="linked in Agilisys" />
                    <h3>LinkedIn</h3>
                    <span>LinkedIn Feed</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>
        
            <div class="col-lg-4 col-md-6">
                <a href="https://twitter.com/agilisys_maroc" class="box" target="_blank">
                    <img src="assets/img/twitter.png" alt="twiter agilisys maroc" />
                    <h3>Twitter</h3>
                    <span>Twitter Feed</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-4 col-md-6">
                <a href="https://www.instagram.com/agilisys_maroc/" class="box" target="_blank">
                    <img src="assets/img/instagram.png" alt="mailchimp" />
                    <h3>Instagram</h3>
                    <span>News Feed</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End Platform Connect -->


   <!-- <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo2.png" alt="logo2"></a>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
                    <ul class="social-list">
                        <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget pl-5">
                    <h3>Company</h3>

                    <ul class="list">
                        <li><a routerLink="/">About</a></li>
                        <li><a routerLink="/">Carrers</a></li>
                        <li><a routerLink="/">Awards</a></li>
                        <li><a routerLink="/">User Program</a></li>
                        <li><a routerLink="/">Locations</a></li>
                        <li><a routerLink="/">Login</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Products</h3>

                    <ul class="list">
                        <li><a routerLink="/">Integrations</a></li>
                        <li><a routerLink="/">API</a></li>
                        <li><a routerLink="/">Pricing</a></li>
                        <li><a routerLink="/">Documentation</a></li>
                        <li><a routerLink="/">Sign Up</a></li>
                        <li><a routerLink="/">Release Notes</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>

                    <ul class="list">
                        <li><a routerLink="/">About</a></li>
                        <li><a routerLink="/">Contact</a></li>
                        <li><a routerLink="/">FAQ</a></li>
                        <li><a routerLink="/">Press</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>-->

    <div class="copyright-area">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>Made with our developers love&nbsp;<i class="icofont-love" style="color: red;"></i></p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Agilisys 2023</a></li>
                       <!-- <li><a routerLink="/">Privacy Policy</a></li>-->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Back Top top -->
<div class="back-to-top">Top</div>
<!-- End Back Top top -->
