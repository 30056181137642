<!-- Start Marketing Main Banner -->
<div class="main-banner-home marketing-home">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content">
                            <h1>
                                Faire de la technologie votre allié et non votre
                                adversaire
                            </h1>
                            <p>
                                Chez AGILISYS Nous sommes convaincus que la
                                véritable réussite consiste à donner à nos
                                clients les moyens d’atteindre leurs objectifs,
                                et c’est ce que nous nous engageons à faire.
                            </p>
                            <!-- <a routerLink="/contact-us" class="btn btn-primary"
                                >Nous contacter</a
                            > -->
                            <a
                                href="/assets/media/presentation_agilisys.pdf"
                                download="presentation_agilisys_2023"
                                class="btn btn-primary"
                                >Notre Brochure</a
                            >
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="marketing-image">
                            <img
                                src="assets/img/marketing-shape/marketing-img1.png"
                                class="wow animate__animated animate__fadeInDown"
                                data-wow-delay="0.4s"
                                alt="img"
                            />
                            <img
                                src="assets/img/agile.png"
                                class="wow animate__animated animate__fadeInLeft"
                                data-wow-delay="0.4s"
                                alt="img"
                            />
                            <img
                                src="assets/img/marketing-shape/marketing-img3.png"
                                class="wow animate__animated animate__lightSpeedIn"
                                data-wow-delay="0.4s"
                                alt="img"
                            />
                            <img
                                src="assets/img/marketing-shape/marketing-img4.png"
                                class="wow animate__animated animate__rollIn"
                                data-wow-delay="0.4s"
                                alt="img"
                            />
                            <img
                                src="assets/img/marketing-shape/marketing-img5.png"
                                class="wow animate__animated animate__fadeInUp"
                                data-wow-delay="0.4s"
                                alt="img"
                            />
                            <img
                                src="assets/img/marketing-shape/marketing-img6.png"
                                class="wow animate__animated animate__fadeInDown"
                                data-wow-delay="0.4s"
                                alt="img"
                            />
                            <img
                                src="assets/img/marketing-shape/marketing-img7.png"
                                class="wow animate__animated animate__flip"
                                data-wow-delay="0.4s"
                                alt="img"
                            />
                            <img
                                src="assets/img/devops.png"
                                class="wow animate__animated animate__zoomIn"
                                data-wow-delay="0.4s"
                                alt="img"
                            />
                            <img src="assets/img/shape6.png" alt="img" />
                            <img
                                src="assets/img/marketing-shape/marketing-img9.png"
                                class="wow animate__animated animate__fadeInUp"
                                data-wow-delay="0.4s"
                                alt="img"
                            />
                            <img
                                src="assets/img/marketing-shape/marketing-img10.png"
                                class="wow animate__animated animate__fadeInUp"
                                data-wow-delay="0.4s"
                                alt="img"
                            />
                            <img
                                src="assets/img/shape6.png"
                                class="wow animate__animated animate__flip"
                                data-wow-delay="0.4s"
                                alt="img"
                            />
                            <img
                                src="assets/img/cccc.png"
                                class="wow animate__animated animate__fadeInUp"
                                data-wow-delay="0.4s"
                                alt="img"
                            />

                            <img
                                src="assets/img/marketing-shape/main-image.png"
                                class="wow animate__animated animate__fadeInUp"
                                data-wow-delay="0.4s"
                                alt="img"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-gray shape-1"></div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="img" /></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img" /></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img" /></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img" /></div>
    <div class="shape8 rotateme">
        <img src="assets/img/shape8.svg" alt="shape" />
    </div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape" /></div>
    <!-- Bubble Animte -->
    <div class="bubble-animate">
        <div class="circle small square1"></div>
        <div class="circle small square2"></div>
        <div class="circle small square3"></div>
        <div class="circle small square4"></div>
        <div class="circle small square5"></div>
        <div class="circle medium square1"></div>
        <div class="circle medium square2"></div>
        <div class="circle medium square3"></div>
        <div class="circle medium square4"></div>
        <div class="circle medium square5"></div>
        <div class="circle large square1"></div>
        <div class="circle large square2"></div>
        <div class="circle large square3"></div>
        <div class="circle large square4"></div>
    </div>
</div>
<!-- End Marketing Main Banner -->

<app-partner></app-partner>

<!-- Start Features Area -->
<section class="features-area marketing-features ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="row desktop">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-holder-box">
                            <img
                                src="assets/img/agilisys/a/agile4.png"
                                alt="Développement Agile"
                            />
                            <h3>Développement Agile</h3>
                        </div>

                        <div class="single-holder-box mt-30">
                            <img
                                src="assets/img/agilisys/a/di2.webp"
                                alt="Transformation Digitale"
                            />
                            <h3>Transformation Digitale</h3>
                        </div>

                        <div class="single-holder-box mt-30">
                            <img
                                src="assets/img/agilisys/a/uiux2.webp"
                                alt="Architecture"
                            />
                            <h3>Architecture</h3>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-holder-box mt-50">
                            <img
                                src="assets/img/agilisys/a/ai1.webp"
                                alt="Data, analytics & IA"
                            />
                            <h3>Data, analytics & IA</h3>
                        </div>

                        <div class="single-holder-box mt-30">
                            <img
                                src="assets/img/agilisys/a/ci3.png"
                                alt="Devops (CI/CD)"
                            />
                            <h3>Devops (CI/CD)</h3>
                        </div>

                        <div class="single-holder-box mt-30">
                            <img
                                src="assets/img/agilisys/a/regie3.png"
                                alt="Régie"
                            />
                            <h3>Régie</h3>
                        </div>
                    </div>
                </div>

                <div class="partner-area mobile">
                    <div class="container">
                        <div class="partner-slides owl-carousel owl-theme">
                            <div class="item">
                                <div class="single-holder-box">
                                    <img
                                        src="assets/img/agilisys/a/agile4.png"
                                        alt="Développement Agile"
                                    />
                                    <h3>Développement Agile</h3>
                                </div>
                            </div>

                            <div class="item">
                                <div class="single-holder-box mt-30">
                                    <img
                                        src="assets/img/agilisys/a/di2.webp"
                                        alt="Transformation Digitale"
                                    />
                                    <h3>Transformation Digitale</h3>
                                </div>
                            </div>
                            <div class="item">
                                <div class="single-holder-box mt-30">
                                    <img
                                        src="assets/img/agilisys/a/uiux2.webp"
                                        alt="Architecture"
                                    />
                                    <h3>Architecture</h3>
                                </div>
                            </div>
                            <div class="item">
                                <div class="single-holder-box mt-50">
                                    <img
                                        src="assets/img/agilisys/a/ai1.webp"
                                        alt="Data, analytics & IA"
                                    />
                                    <h3>Data, analytics & IA</h3>
                                </div>
                            </div>
                            <div class="item">
                                <div class="single-holder-box mt-30">
                                    <img
                                        src="assets/img/agilisys/a/ci3.png"
                                        alt="Devops (CI/CD)"
                                    />
                                    <h3>Devops (CI/CD)</h3>
                                </div>
                            </div>
                            <div class="item">
                                <div class="single-holder-box mt-30">
                                    <img
                                        src="assets/img/agilisys/a/regie3.png"
                                        alt="Régie"
                                    />
                                    <h3>Régie</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="features-holder-content">
                    <div class="section-title">
                        <h2>Percer la complexité de la technologie</h2>
                        <div class="bar"></div>
                        <p>
                            Nous comprenons que dans le labyrinthe de l'IT
                            moderne, la simplicité est la sophistication ultime.
                            Notre objectif est de démystifier la technologie et
                            de rendre les solutions informatiques puissantes non
                            seulement accessibles, mais aussi adaptables aux
                            défis et opportunités uniques que nos clients
                            rencontrent. Nous nous efforçons de doter nos
                            clients d'outils qui parlent leur langue, en
                            favorisant un environnement où la technologie
                            accélère la croissance au lieu de la compliquer.
                        </p>
                    </div>
                    <p>
                        En fournissant des logiciels sur mesure qui s'alignent
                        sur les stratégies commerciales, nous assurons que la
                        technologie est un catalyseur pour l'innovation,
                        stimulant l'efficacité et l'avantage concurrentiel.
                        Notre engagement est d'être les architectes d'un paysage
                        numérique où nos clients naviguent avec aisance et
                        confiance, toujours soutenus par nos conseils d'experts.
                    </p>
                    <!-- <a routerLink="/services" class="btn btn-primary"
                        >Nos Services</a
                    > -->
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="features-inner-area">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-inner-content">
                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-ui-call"></i>
                            </div>
                            <h3>Free Caliing Service</h3>
                            <p>
                                Plan ahead by day, week, or month, and see
                                project status at a glance. Search and filter to
                                focus in on anything form a single project to an
                                individual person's workload.
                            </p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-gift"></i>
                            </div>
                            <h3>Daily Free Gift</h3>
                            <p>
                                Plan ahead by day, week, or month, and see
                                project status at a glance. Search and filter to
                                focus in on anything form a single project to an
                                individual person's workload.
                            </p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-qr-code"></i>
                            </div>
                            <h3>QR Code Scaner</h3>
                            <p>
                                Plan ahead by day, week, or month, and see
                                project status at a glance. Search and filter to
                                focus in on anything form a single project to an
                                individual person's workload.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="features-image">
                        <img src="assets/img/features-img1.png" alt="image" />
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape" /></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img" /></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img" /></div>
    <div class="shape8 rotateme">
        <img src="assets/img/shape8.svg" alt="shape" />
    </div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape" /></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape" /></div>
    <div class="shape11 rotateme">
        <img src="assets/img/shape11.svg" alt="shape" />
    </div>
</section>
<!-- End Features Area -->

<!-- Start Services Area -->
<!-- <section class="services-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Our Services</h2>
            <div class="bar"></div>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-pay"></i>
                    </div>
                    <h3>Search Strategy</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-music-note"></i>
                    </div>
                    <h3>Onsite SEO</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-optic"></i>
                    </div>
                    <h3>Keyword Targeting</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-pie-chart"></i>
                    </div>
                    <h3>Link Building</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-deviantart"></i>
                    </div>
                    <h3>CPA Marketing</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-chart-pie-alt"></i>
                    </div>
                    <h3>Report Analysis</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Services Area -->

<!-- Start Overview Area -->
<section class="overview-section ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/marketing-1.png" alt="image" />
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-power"></i>
                    </div>
                    <h3>Développement spécifique</h3>
                    <p>
                        Que ça soit en Agile ou en méthodologie classique
                        (waterfall ), Agilisys s’engage à offrir des solutions
                        modulaires, innovantes et scalables, adressant des
                        domaines fonctionnels différents.
                    </p>
                    <ul>
                        <li>Respect de l’état de l’art</li>
                        <li>Interopérabilité</li>
                        <li>Solutions orientées intégration</li>
                        <li>
                            Haut degré de configuration et de paramétrabilité
                        </li>
                    </ul>
                    <!--<a routerLink="/" class="btn btn-primary">Read More</a>-->
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-share-alt"></i>
                    </div>
                    <h3>Edition de produits</h3>
                    <p>
                        Agilisys a mis toute son expertise fonctionnelle et
                        technique acquise sur le terrain avec ses partenaires /
                        Clients pour mettre en place des solution de gestion qui
                        répondent aux besoins de ces clients intervenants dans
                        le domaine immobilier et de la santé.
                    </p>
                    <ul>
                        <li>PROMOTUS IMO</li>
                        <li>PATRIMO</li>
                        <li>DOCDISPO</li>
                    </ul>
                    <!-- <a routerLink="/" class="btn btn-primary">Read More</a>-->
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/marketing-2.png" alt="image" />
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/marketing.png" alt="image" />
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-ui-messaging"></i>
                    </div>
                    <h3>Formations</h3>
                    <p>
                        Nos spécialistes seront à votre écoute pour vous
                        apporter toutes les connaissances dont vous aurez
                        besoin. À la fois théoriques et pratiques. Nos cursus
                        vous fourniront les méthodes clés à la réussite de tous
                        types de projets et vous assurons la meilleure boite a
                        outils issue des meilleures pratiques sur le marché.
                    </p>
                    <ul>
                        <li>Formation en gestion de projet</li>
                        <li>Formations en technologie</li>
                    </ul>
                    <!-- <a routerLink="/" class="btn btn-primary">Read More</a> -->
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-users-social"></i>
                    </div>
                    <h3>Régie : nos experts sont les meilleurs</h3>
                    <p>
                        Au-delà de l’expertise technique, nous attachons
                        beaucoup d’importance à la personnalité de nos
                        consultants (polyvalence, curiosité, rigueur et
                        disponibilité) . Nos experts disposent également d’un
                        bon relationnel et d’un bon rédactionnel.
                    </p>
                    <ul>
                        <li>Ingénierie informatique</li>
                        <li>Gestion de projet et de portefeuille</li>
                        <li>AMO et Audit des SI</li>
                        <li>Développement informatique</li>
                    </ul>
                    <!--   <a routerLink="/" class="btn btn-primary">Read More</a> -->
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/marketing-3.png" alt="image" />
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape" /></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img" /></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img" /></div>
    <div class="shape8 rotateme">
        <img src="assets/img/shape8.svg" alt="shape" />
    </div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape" /></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape" /></div>
    <div class="shape11 rotateme">
        <img src="assets/img/shape11.svg" alt="shape" />
    </div>
</section>
<!-- End Overview Area -->

<!-- Start Join Area 
<section class="join-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="join-img">
                    <img src="assets/img/3.png" alt="img" />
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="join-content">
                    <span>Start 30 Days Free Trial</span>
                    <h3>Overall 400k+ Our Clients! Please Get Start Now</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo.
                    </p>
                    <a routerLink="/contact-us" class="btn btn-primary"
                        >Join Now</a
                    >
                </div>
            </div>
        </div>
    </div>
</section>-->
<!-- End Join Area -->

<!-- Start Analysis Area -->
<!-- <section class="analysis-area ptb-100 bg-gray">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="analysis-form">
                    <h3>Free SEO Analysis</h3>

                    <form>
                        <div class="form-group">
                            <input
                                type="text"
                                placeholder="Name*"
                                class="form-control"
                            />
                        </div>

                        <div class="form-group">
                            <input
                                type="email"
                                placeholder="Email*"
                                class="form-control"
                            />
                        </div>

                        <div class="form-group">
                            <input
                                type="text"
                                placeholder="Phone*"
                                class="form-control"
                            />
                        </div>

                        <div class="form-group">
                            <input
                                type="text"
                                placeholder="Website*"
                                class="form-control"
                            />
                        </div>

                        <div class="form-group">
                            <input
                                type="text"
                                placeholder="Company Name*"
                                class="form-control"
                            />
                        </div>

                        <button type="submit" class="btn btn-primary">
                            Get Free Report
                        </button>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="analysis-text">
                    <span>Free SEO Analysis</span>
                    <h3>Be on the Top & Get More Traffic to Your Website</h3>
                    <p>
                        The following services explain how we approach SEO for a
                        range of common purposes:
                    </p>
                    <ul>
                        <li>
                            If Google can’t crawl your site, it’s not going to
                            rank – but that doesn’t mean avoiding Javascript.
                        </li>
                        <li>
                            For businesses which sell products online and and
                            improve their product listings in the search
                            results.
                        </li>
                        <li>
                            If you’re investing in a new website it’s important
                            to ensure it’s built to succeed in the search
                            results too.
                        </li>
                        <li>
                            If Google can’t crawl your site, it’s not going to
                            rank – but that doesn’t mean avoiding Javascript.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Analysis Area -->

<!-- Start App Funfacts Area -->
<!-- <section class="app-funfacts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-funfact-box">
                    <div class="icon">
                        <i class="icofont-users-alt-5"></i>
                    </div>
                    <h3><span class="count">2150</span>+</h3>
                    <p>Active Clients</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-funfact-box">
                    <div class="icon">
                        <i class="icofont-rocket-alt-1"></i>
                    </div>
                    <h3><span class="count">86</span>%</h3>
                    <p>Success Rate</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-funfact-box">
                    <div class="icon">
                        <i class="icofont-laptop-alt"></i>
                    </div>
                    <h3><span class="count">550</span>+</h3>
                    <p>Projects Done</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-funfact-box">
                    <div class="icon">
                        <i class="icofont-win-trophy"></i>
                    </div>
                    <h3><span class="count">35</span>+</h3>
                    <p>Awards</p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End App Funfacts Area -->

<!-- Start Project Area -->
<!-- <section class="project-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Recent Work</h2>
            <div class="bar"></div>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
        </div>
    </div>

    <div class="project-slides owl-carousel owl-theme">
        <div class="single-project">
            <div class="project-image">
                <img src="assets/img/work-img1.jpg" alt="work" />
                <a href="assets/img/work-img1.jpg" class="popup-btn"
                    ><i class="icofont-plus"></i
                ></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>

        <div class="single-project">
            <div class="project-image">
                <img src="assets/img/work-img2.jpg" alt="work" />
                <a href="assets/img/work-img2.jpg" class="popup-btn"
                    ><i class="icofont-plus"></i
                ></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>

        <div class="single-project">
            <div class="project-image">
                <img src="assets/img/work-img3.jpg" alt="work" />
                <a href="assets/img/work-img3.jpg" class="popup-btn"
                    ><i class="icofont-plus"></i
                ></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>

        <div class="single-project">
            <div class="project-image">
                <img src="assets/img/work-img4.jpg" alt="work" />
                <a href="assets/img/work-img4.jpg" class="popup-btn"
                    ><i class="icofont-plus"></i
                ></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>

        <div class="single-project">
            <div class="project-image">
                <img src="assets/img/work-img5.jpg" alt="work" />
                <a href="assets/img/work-img5.jpg" class="popup-btn"
                    ><i class="icofont-plus"></i
                ></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>

        <div class="single-project">
            <div class="project-image">
                <img src="assets/img/work-img6.jpg" alt="work" />
                <a href="assets/img/work-img6.jpg" class="popup-btn"
                    ><i class="icofont-plus"></i
                ></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>
    </div>

    <canvas id="canvas"></canvas>
</section> -->
<!-- End Project Area -->

<!-- Start Team Area -->
<!-- <section class="team-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Our Smart Team</h2>
            <div class="bar"></div>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
        </div>

        <div class="team-slides owl-carousel owl-theme">
            <div class="single-team-member-box">
                <img src="assets/img/team-img1.jpg" alt="team" />

                <div class="team-content">
                    <h3>John Doe</h3>
                    <span>CEO</span>
                    <ul>
                        <li>
                            <a href="#" target="_blank" class="facebook"
                                ><i class="icofont-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank" class="instagram"
                                ><i class="icofont-instagram"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank" class="twitter"
                                ><i class="icofont-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank" class="linkedin"
                                ><i class="icofont-linkedin"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member-box">
                <img src="assets/img/team-img2.jpg" alt="team" />

                <div class="team-content">
                    <h3>Steven Smith</h3>
                    <span>Developer</span>
                    <ul>
                        <li>
                            <a href="#" target="_blank" class="facebook"
                                ><i class="icofont-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank" class="instagram"
                                ><i class="icofont-instagram"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank" class="twitter"
                                ><i class="icofont-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank" class="linkedin"
                                ><i class="icofont-linkedin"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member-box">
                <img src="assets/img/team-img3.jpg" alt="team" />

                <div class="team-content">
                    <h3>David Luiz</h3>
                    <span>Designer</span>
                    <ul>
                        <li>
                            <a href="#" target="_blank" class="facebook"
                                ><i class="icofont-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank" class="instagram"
                                ><i class="icofont-instagram"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank" class="twitter"
                                ><i class="icofont-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank" class="linkedin"
                                ><i class="icofont-linkedin"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member-box">
                <img src="assets/img/team-img4.jpg" alt="team" />

                <div class="team-content">
                    <h3>Park Tim</h3>
                    <span>SEO Expert</span>
                    <ul>
                        <li>
                            <a href="#" target="_blank" class="facebook"
                                ><i class="icofont-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank" class="instagram"
                                ><i class="icofont-instagram"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank" class="twitter"
                                ><i class="icofont-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank" class="linkedin"
                                ><i class="icofont-linkedin"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member-box">
                <img src="assets/img/team-img5.jpg" alt="team" />

                <div class="team-content">
                    <h3>Doglas Costa</h3>
                    <span>Manager</span>
                    <ul>
                        <li>
                            <a href="#" target="_blank" class="facebook"
                                ><i class="icofont-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank" class="instagram"
                                ><i class="icofont-instagram"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank" class="twitter"
                                ><i class="icofont-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank" class="linkedin"
                                ><i class="icofont-linkedin"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Team Area -->

<!-- Start Pricing Area -->
<!-- <section class="pricing-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Easy Pricing Plans</h2>
            <div class="bar"></div>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="pricing-table-box">
                    <div class="pricingTable-header">
                        <h3 class="title">Basic</h3>
                        <div class="price-value">
                            <sup>$</sup>59.99 <span>/per year</span>
                        </div>
                    </div>

                    <ul class="pricing-content">
                        <li>Managment & Marketing</li>
                        <li>SEO Optimization</li>
                        <li>25 Analytics Campaign</li>
                        <li>1,300 Change Keywords</li>
                        <li>25 Social Media Reviews</li>
                        <li>1 Free Optimization</li>
                        <li>24/7 Support</li>
                    </ul>

                    <a routerLink="/" class="btn btn-primary">Buy Now</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="pricing-table-box">
                    <div class="pricingTable-header">
                        <h3 class="title">Ultra</h3>
                        <div class="price-value">
                            <sup>$</sup>79.99 <span>/per year</span>
                        </div>
                    </div>

                    <ul class="pricing-content">
                        <li>Managment & Marketing</li>
                        <li>SEO Optimization</li>
                        <li>25 Analytics Campaign</li>
                        <li>1,300 Change Keywords</li>
                        <li>25 Social Media Reviews</li>
                        <li>1 Free Optimization</li>
                        <li>24/7 Support</li>
                    </ul>

                    <a routerLink="/" class="btn btn-primary">Buy Now</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="pricing-table-box">
                    <div class="pricingTable-header">
                        <h3 class="title">Pro</h3>
                        <div class="price-value">
                            <sup>$</sup>99.99 <span>/per year</span>
                        </div>
                    </div>

                    <ul class="pricing-content">
                        <li>Managment & Marketing</li>
                        <li>SEO Optimization</li>
                        <li>25 Analytics Campaign</li>
                        <li>1,300 Change Keywords</li>
                        <li>25 Social Media Reviews</li>
                        <li>1 Free Optimization</li>
                        <li>24/7 Support</li>
                    </ul>

                    <a routerLink="/" class="btn btn-primary">Buy Now</a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Pricing Area -->

<!-- Start Blog Area -->
<!-- <section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest News</h2>
            <div class="bar"></div>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
        </div>

        <div class="blog-slides owl-carousel owl-theme">
            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"
                    ><img src="assets/img/blog-img1.jpg" alt="blog-image"
                /></a>

                <div class="blog-post-content">
                    <ul>
                        <li>
                            <i class="icofont-user-male"></i>
                            <a routerLink="/blog">Admin</a>
                        </li>
                        <li>
                            <i class="icofont-wall-clock"></i> January 23, 2024
                        </li>
                    </ul>
                    <h3>
                        <a routerLink="/blog-details"
                            >The Most Popular New Business Apps</a
                        >
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi turpis massa, dapibus nec libero vitae.
                    </p>
                    <a routerLink="/blog-details" class="read-more-btn"
                        >Read More <i class="icofont-rounded-double-right"></i
                    ></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"
                    ><img src="assets/img/blog-img2.jpg" alt="blog-image"
                /></a>

                <div class="blog-post-content">
                    <ul>
                        <li>
                            <i class="icofont-user-male"></i>
                            <a routerLink="/blog">Admin</a>
                        </li>
                        <li>
                            <i class="icofont-wall-clock"></i> January 16, 2024
                        </li>
                    </ul>
                    <h3>
                        <a routerLink="/blog-details"
                            >The Best Marketing Management Tools</a
                        >
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi turpis massa, dapibus nec libero vitae.
                    </p>
                    <a routerLink="/blog-details" class="read-more-btn"
                        >Read More <i class="icofont-rounded-double-right"></i
                    ></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"
                    ><img src="assets/img/blog-img3.jpg" alt="blog-image"
                /></a>

                <div class="blog-post-content">
                    <ul>
                        <li>
                            <i class="icofont-user-male"></i>
                            <a routerLink="/blog">Admin</a>
                        </li>
                        <li>
                            <i class="icofont-wall-clock"></i> January 14, 2024
                        </li>
                    </ul>
                    <h3>
                        <a routerLink="/blog-details"
                            >3 WooCommerce Plugins to Boost Sales</a
                        >
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi turpis massa, dapibus nec libero vitae.
                    </p>
                    <a routerLink="/blog-details" class="read-more-btn"
                        >Read More <i class="icofont-rounded-double-right"></i
                    ></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"
                    ><img src="assets/img/blog-img4.jpg" alt="blog-image"
                /></a>

                <div class="blog-post-content">
                    <ul>
                        <li>
                            <i class="icofont-user-male"></i>
                            <a routerLink="/blog">Admin</a>
                        </li>
                        <li>
                            <i class="icofont-wall-clock"></i> January 06, 2024
                        </li>
                    </ul>
                    <h3>
                        <a routerLink="/blog-details"
                            >CakeMail Review – Design Custom Emails</a
                        >
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi turpis massa, dapibus nec libero vitae.
                    </p>
                    <a routerLink="/blog-details" class="read-more-btn"
                        >Read More <i class="icofont-rounded-double-right"></i
                    ></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"
                    ><img src="assets/img/blog-img5.jpg" alt="blog-image"
                /></a>

                <div class="blog-post-content">
                    <ul>
                        <li>
                            <i class="icofont-user-male"></i>
                            <a routerLink="/blog">Admin</a>
                        </li>
                        <li>
                            <i class="icofont-wall-clock"></i> January 04, 2024
                        </li>
                    </ul>
                    <h3>
                        <a routerLink="/blog-details"
                            >The Most Popular New Apps in 2024</a
                        >
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi turpis massa, dapibus nec libero vitae.
                    </p>
                    <a routerLink="/blog-details" class="read-more-btn"
                        >Read More <i class="icofont-rounded-double-right"></i
                    ></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"
                    ><img src="assets/img/blog-img3.jpg" alt="blog-image"
                /></a>

                <div class="blog-post-content">
                    <ul>
                        <li>
                            <i class="icofont-user-male"></i>
                            <a routerLink="/blog">Admin</a>
                        </li>
                        <li>
                            <i class="icofont-wall-clock"></i> January 26, 2024
                        </li>
                    </ul>
                    <h3>
                        <a routerLink="/blog-details"
                            >The Fastest Growing Apps in 2024</a
                        >
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi turpis massa, dapibus nec libero vitae.
                    </p>
                    <a routerLink="/blog-details" class="read-more-btn"
                        >Read More <i class="icofont-rounded-double-right"></i
                    ></a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Blog Area -->

<!-- Start Feedback Area -->
<section class="feedback-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Témoignages</h2>
            <div class="bar"></div>
            <p>Ce que disent nos clients de nous ...</p>
        </div>

        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/clients/7.webp" alt="client" />
                    <h3>Ahmed Abu Alathae</h3>
                    <span>Responsable achat et finance Sindipark </span>
                </div>
                <p>
                    L'équipe AGILISYS a transformé notre vision en réalité avec
                    une solution parfaitement adaptée à nos besoins uniques.
                    Leur approche personnalisée et leur souci du détail ont
                    significativement amélioré notre efficacité opérationnelle.
                    Leur réactivité face à nos demandes et leur capacité à
                    résoudre les problèmes rapidement ont été particulièrement
                    impressionnantes. Un partenaire de choix pour toute
                    entreprise à la recherche de solutions informatiques sur
                    mesure
                </p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/clients/4.webp" alt="GPS" />
                    <h3>Saad El KENZI</h3>
                    <span>Co-fondateur GPS </span>
                </div>
                <p>
                    En tant que startup, nous avions besoin d'un partenaire
                    capable de s'adapter rapidement à notre croissance et à nos
                    évolutions. AGILISYS a dépassé toutes nos attentes, en
                    livrant un produit exceptionnel qui a joué un rôle clé dans
                    notre succès. Leur flexibilité, leur engagement et leur
                    expertise technique sont inégalés. Ils ont véritablement
                    compris notre vision et l'ont concrétisée
                </p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/clients/6.webp" alt="client" />
                    <h3>Widad Hjiaj</h3>
                    <span>Directrice Commerciale Sindibad Beach Resort </span>
                </div>
                <p>
                    Ce qui distingue AGILISYS, c'est leur capacité à résoudre
                    des problèmes complexes de manière créative. Le logiciel
                    qu'ils ont développé pour nous est devenu essentiel à notre
                    opération. Ils ont une forte culture de l'innovation et une
                    réelle volonté de pousser les limites de ce qui est
                    techniquement possible. Leur engagement envers la qualité et
                    l'excellence est évident dans tout ce qu'ils font. Un
                    partenaire exceptionnel pour toute entreprise en quête de
                    solutions logicielles avancées
                </p>
            </div>

            <!--<div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/client4.jpg" alt="client" />
                    <h3>David Warner</h3>
                    <span>Web Developer</span>
                </div>
                <p>
                    Plan ahead by day, week, or month, and see project status at
                    a glance. Search and filter to focus in on anything form a
                    single project individual. Plan ahead by day, week, or
                    month, and see project status at a glance.
                </p>
            </div>-->
        </div>
    </div>

    <svg
        class="svg-feedback-bottom"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
    >
        <path d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z" fill="#ffffff" />
    </svg>
</section>
