import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Component({
    selector: "app-SwiftBridge-landing",
    templateUrl: "./EdiOdc.component.html",
    styleUrls: ["./EdiOdc.component.scss"],
})
export class EdiOdcComponent implements OnInit {
    constructor(private titleService: Title, private meta: Meta) {
        this.titleService.setTitle("Agilisys Maroc - EDI ODC");

        this.meta.updateTag({
            name: "description",
            content:
                "Automatise l’élaboration des Reportings Office de Change RFFI et RCCPI conformément à la réglementation en vigueur, dématérialise leur envoi et assure le monitoring nécessaire, ceci dans l’objectif de réduire la charge déclarative de la banque vis-à-vis du régulateur.",
        });

        this.meta.updateTag({
            name: "keywords",
            content:
                "Élaboration des Reportings Office de Change RFFI et RCCPI, Automatisation des Reportings Office de Change, Automatisation des Reportings RFFI et RCCPI, Conformité réglementaire, Dématérialisation des envois, Monitoring des Reportings, Réduction de la charge déclarative, Banque vis-à-vis du régulateur, Génération automatique des Reportings, Suivi des exigences réglementaires, Gestion des déclarations bancaires, Simplification des obligations déclaratives, Réduction du fardeau administratif, Respect des normes réglementaires, Optimisation des processus déclaratifs",
        });
    }

    ngOnInit() {}
}
