import { Component, HostListener, OnInit } from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
})
export class HeaderComponent implements OnInit {
    location: any;
    logo: any;
    isLargeScreen: boolean = true;

    constructor(
        private router: Router,
        location: Location,
        private breakpointObserver: BreakpointObserver
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                const navbarTogglerButton =
                    document.getElementById("navbar-toggler");
                if (navbarTogglerButton) {
                    const ariaExpanded =
                        navbarTogglerButton.getAttribute("aria-expanded");
                    if (ariaExpanded == "true") {
                        navbarTogglerButton.click();
                    }
                }
            }
        });
    }

    ngOnInit() {
        this.checkWidth()
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.location = this.router.url;
                if (this.location === "/digital-marketing") {
                    this.logo = "logo2.png";
                } else {
                    this.logo = "logo2.png";
                }
            }
        });
    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.checkWidth()
    }

    checkWidth(){
        const breakpoints = {
            small: 600,
            medium: 960,
        };
        if (
            window.innerWidth <= breakpoints.small ||
            window.innerWidth <= breakpoints.medium
        ) {
            this.isLargeScreen = false;
        } else {
            this.isLargeScreen = true;
        }
    }
}
