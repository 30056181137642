import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Component({
    selector: "app-SwiftBridge-landing",
    templateUrl: "./EdiPortnet.component.html",
    styleUrls: ["./EdiPortnet.component.scss"],
})
export class EdiPortnetComponent implements OnInit {
    constructor(private titleService: Title, private meta: Meta) {
        this.titleService.setTitle("Agilisys Maroc - EDI PORTNET");
        this.meta.updateTag({
            name: "description",
            content:
                "Intègre automatiquement vos Domiciliations de Portnet, assure un contrôle technique des flux, facilite le traitement des opérations via une IHM conviviale et intuitive et vous assure un monitoring et un suivi rapproché de votre chaîne de traitement.",
        });

        this.meta.updateTag({
            name: "keywords",
            content:
                "Intègre automatiquement vos Domiciliations de Portnet, Contrôle technique des flux, Traitement des opérations, IHM conviviale, IHM intuitive, Monitoring de la chaîne de traitement, Suivi rapproché de la chaîne de traitement, Gestion des Domiciliations de Portnet, Automatisation des flux, Contrôle des opérations, Interface utilisateur conviviale, Interface utilisateur intuitive, Suivi des opérations, Gestion de flux, Suivi de flux",
        });
    }

    ngOnInit() {}
}
