<!-- Start Page Title Area -->
<section class="page-title-banner">
    <!-- <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Error</h2>
            </div>
        </div>
    </div> -->

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start Error Area -->
<section class="error-area ptb-100">
    <div class="container">
        <div class="notfound_commingsoon">
            <!-- <div class="notfound-coming-soon"></div> -->
            <h2>Page en construction</h2>
            <span>Désolé, mais la page que vous recherchez est temporairement indisponible. <br>Revenez bientôt pour découvrir notre nouveau contenu!</span>
            <a href="/" class="btn btn-primary">Retour à la page d'accueil</a>
        </div>
    </div>
</section>
<!-- End Error Area -->