<!-- Start Page Title Area -->
<!-- <section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2 *ngIf="id == 'compliflow'" style="color: #ff5454">CompliFlow</h2>
                <h2 *ngIf="id == 'domiflow'" style="color: #ff5454">DomiFlow</h2>
                <h2 *ngIf="id == 'pain001'" style="color: #ff5454">Pain001</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img" /></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img" /></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img" /></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img" /></div>
    <div class="shape8 rotateme">
        <img src="assets/img/shape8.svg" alt="shape" />
    </div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape" /></div>
</section> -->
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<div *ngIf="id == 'compliflow'">
    <section class="blog-details-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="blog-details">
                        <div class="article-img">
                            <div style="display: flex; justify-content: center">
                                <img
                                    src="assets/img/compliflow-small.png"
                                    alt="blog-details"
                                />
                            </div>
                            <div class="date">
                                03 <br />
                                2023
                            </div>
                        </div>

                        <div class="article-content">
                            <!-- <ul class="category">
                                <li><a routerLink="/blog">IT</a></li>
                                <li><a routerLink="/blog">Mobile</a></li>
                                <li><a routerLink="/blog">Marketing</a></li>
                                <li><a routerLink="/blog">Design</a></li>
                                <li><a routerLink="/blog">Development</a></li>
                            </ul> -->
                            <h3>
                                Optimisation des contrôles Conformité et
                                Règlementaires avec Compliflow
                            </h3>

                            <p>
                                Dans un environnement bancaire de plus en plus
                                réglementer, les banques locales faisaient face
                                à des défis complexes pour garantir la
                                conformité, en particulier sur le suivi des
                                sanctions et embargos, avec un processus manuel
                                chronophage et sujet à erreurs.
                            </p>

                            <div class="overview-box">
                                <div class="overview-content">
                                    <h3>Challenge :</h3>
                                    <br />
                                    <ul>
                                        <li>
                                            Gestion manuelle des contrôles de
                                            conformité.
                                        </li>
                                        <br />
                                        <li>
                                            Difficulté à assurer une
                                            surveillance efficace des sanctions
                                            et embargos.
                                        </li>
                                        <br />
                                        <li>
                                            Manque de traçabilité et
                                            sécurisation des pistes d’audit.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="overview-box">
                                <div class="overview-content">
                                    <h3>Solution Apportée :</h3>

                                    <p>
                                        Avec la mise en œuvre de notre solution
                                        <b>Compliflow</b>, la <b>SGMA</b> ,<b
                                            >DAR AL AMANE</b
                                        >
                                        et la <b>SGOS</b> ont pu automatiser et
                                        centraliser l’ensemble de leurs
                                        contrôles de conformité.
                                    </p>
                                </div>
                            </div>

                            <div class="overview-box">
                                <div class="overview-content">
                                    <h3>Résultats Clés :</h3>
                                    <br />
                                    <ul>
                                        <li>
                                            <b>Réduction de 40 %</b> des erreurs
                                            de conformité.
                                        </li>
                                        <br />
                                        <li>
                                            <b>Gain de 30 %</b> de temps sur les
                                            processus de contrôle.
                                        </li>
                                        <br />
                                        <li>
                                            <b
                                                >Amélioration de la
                                                Traçabilité</b
                                            >
                                            grâce à l'automatisation des pistes
                                            d'audit.
                                        </li>
                                        <br />
                                        <li>
                                            <b>Conformité Renforcée</b> avec une
                                            surveillance proactive et continue.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="overview-box">
                                <div class="overview-content">
                                    <h3>Témoignage Client :</h3>

                                    <p>
                                        <cite
                                            >“ Compliflow nous a permis
                                            d’automatiser entièrement nos
                                            processus de conformité, nous
                                            assurant une surveillance continue
                                            et une tranquillité d’esprit totale.
                                            ”</cite
                                        >
                                        – DP GTPS SGMA.
                                    </p>
                                </div>
                            </div>

                            <div class="overview-box">
                                <div class="overview-content">
                                    <h3>Conclusion :</h3>

                                    <p>
                                        La solution <b>Compliflow</b> a permis à
                                        ces banques d’optimiser
                                        significativement leurs processus de
                                        conformité, garantissant à la fois un
                                        gain d’efficacité et une meilleure
                                        gestion des risques réglementaires et
                                        conformité , évitant ainsi les sanctions
                                        et les fraudes .
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="post-controls-buttons">
                        <div class="controls-left">
                            <a routerLink="/blog-details"><i class="icofont-double-left"></i> Prev Post</a>
                        </div>
    
                        <div class="controls-right">
                            <a routerLink="/blog-details">Next Post <i class="icofont-double-right"></i></a>
                        </div>
                    </div>
    
                    <div class="post-comments">
                        <h3>Comments</h3>
                        <div class="single-comment">
                            <div class="comment-img">
                                <img src="assets/img/client1.jpg" alt="client">
                            </div>
                            <div class="comment-content">
                                <h4>John Smith</h4>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                                <span>Jan 19, 2024 - 9:10AM</span>
                                <a routerLink="/blog-details">Reply</a>
                            </div>
                        </div>
    
                        <div class="single-comment left-m">
                            <div class="comment-img">
                                <img src="assets/img/client2.jpg" alt="client">
                            </div>
                            <div class="comment-content">
                                <h4>Doe John</h4>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                                <span>Jan 19, 2024 - 9:10AM</span>
                                <a routerLink="/blog-details">Reply</a>
                            </div>
                        </div>
    
                        <div class="single-comment">
                            <div class="comment-img">
                                <img src="assets/img/client3.jpg" alt="client">
                            </div>
                            <div class="comment-content">
                                <h4>Steven Doe</h4>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                                <span>Jan 19, 2024 - 9:10AM</span>
                                <a routerLink="/blog-details">Reply</a>
                            </div>
                        </div>
    
                        <div class="single-comment">
                            <div class="comment-img">
                                <img src="assets/img/client4.jpg" alt="client">
                            </div>
                            <div class="comment-content">
                                <h4>John Cina</h4>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                                <span>Jan 19, 2024 - 9:10AM</span>
                                <a routerLink="/blog-details">Reply</a>
                            </div>
                        </div>
                    </div>
    
                    <div class="leave-a-reply">
                        <h3>Leave a Reply</h3>
                        
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Full Name">
                                </div>
                            </div>
    
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="E-Mail">
                                </div>
                            </div>
    
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="comment" cols="30" rows="5" class="form-control" placeholder="Your Comment"></textarea>
                                </div>
                            </div>
    
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div> -->
                </div>

                <!-- <div class="col-lg-4 col-md-12">
                    <div class="sidebar-area">
                        <div class="widget widget-search">
                            <form>
                                <input type="text" class="form-control" placeholder="Search">
                                <button type="submit"><i class="icofont-search-2"></i></button>
                            </form>
                        </div>
    
                        <div class="widget widget_post_categories">
                            <h3 class="widget-title">Post Categories</h3>
                            <div class="bar"></div>
                            <ul>
                                <li><a routerLink="/blog"><i class="icofont-bubble-right"></i> Art</a></li>
                                <li><a routerLink="/blog"><i class="icofont-bubble-right"></i> Book</a></li>
                                <li><a routerLink="/blog"><i class="icofont-bubble-right"></i> Watch</a></li>
                                <li><a routerLink="/blog"><i class="icofont-bubble-right"></i> TV</a></li>
                                <li><a routerLink="/blog"><i class="icofont-bubble-right"></i> Gifts</a></li>
                                <li><a routerLink="/blog"><i class="icofont-bubble-right"></i> Design</a></li>
                                <li><a routerLink="/blog"><i class="icofont-bubble-right"></i> Smart TV</a></li>
                            </ul>
                        </div>
    
                        <div class="widget widget_recent_posts">
                            <h3 class="widget-title">Recent Post</h3>
                            <div class="bar"></div>
                            <ul>
                                <li>
                                    <div class="recent-post-thumb">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog-img1.jpg" alt="blog-image">
                                        </a>
                                    </div>
                                    <div class="recent-post-content">
                                        <h3><a routerLink="/blog-details">The Fastest Growing Apps in 2024</a></h3>
                                        <span class="date">23 January 2024</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="recent-post-thumb">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog-img2.jpg" alt="blog-image">
                                        </a>
                                    </div>
                                    <div class="recent-post-content">
                                        <h3><a routerLink="/blog-details">The Most Popular New Business Apps</a></h3>
                                        <span class="date">16 January 2024</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="recent-post-thumb">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog-img3.jpg" alt="blog-image">
                                        </a>
                                    </div>
                                    <div class="recent-post-content">
                                        <h3><a routerLink="/blog-details">3 WooCommerce Plugins to Boost Sales</a></h3>
                                        <span class="date">14 January 2024</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="recent-post-thumb">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog-img4.jpg" alt="blog-image">
                                        </a>
                                    </div>
                                    <div class="recent-post-content">
                                        <h3><a routerLink="/blog-details">CakeMail Review – Design Custom Emails</a></h3>
                                        <span class="date">06 January 2024</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
    
                        <div class="widget widget_tag_cloud">
                            <h3 class="widget-title">Popular Tags</h3>
                            <div class="bar"></div>
                            <div class="tagcloud">
                                <a routerLink="/blog">Art</a>
                                <a routerLink="/blog">Book</a>
                                <a routerLink="/blog">Watch</a>
                                <a routerLink="/blog">TV</a>
                                <a routerLink="/blog">Gifts</a>
                                <a routerLink="/blog">Smart TV</a>
                                <a routerLink="/blog">Design</a>
                            </div>
                        </div>
    
                        <div class="widget widget_text">
                            <h3 class="widget-title">Instagram</h3>
                            <div class="bar"></div>
                            <ul>
                                <li><a href="#" target="_blank"><img src="assets/img/work-img1.jpg" alt="image"></a></li>
                                <li><a href="#" target="_blank"><img src="assets/img/work-img2.jpg" alt="image"></a></li>
                                <li><a href="#" target="_blank"><img src="assets/img/work-img3.jpg" alt="image"></a></li>
                                <li><a href="#" target="_blank"><img src="assets/img/work-img4.jpg" alt="image"></a></li>
                                <li><a href="#" target="_blank"><img src="assets/img/work-img5.jpg" alt="image"></a></li>
                                <li><a href="#" target="_blank"><img src="assets/img/work-img6.jpg" alt="image"></a></li>
                            </ul>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
</div>

<div *ngIf="id == 'domiflow'">
    <section class="blog-details-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="blog-details">
                        <div class="article-img">
                            <div style="display: flex; justify-content: center">
                                <img
                                    src="assets/img/domiflow-small.png"
                                    alt="blog-details"
                                />
                            </div>
                            <div class="date">
                                02 <br />
                                2023
                            </div>
                        </div>

                        <div class="article-content">
                            <!-- <ul class="category">
                                <li><a routerLink="/blog">IT</a></li>
                                <li><a routerLink="/blog">Mobile</a></li>
                                <li><a routerLink="/blog">Marketing</a></li>
                                <li><a routerLink="/blog">Design</a></li>
                                <li><a routerLink="/blog">Development</a></li>
                            </ul> -->
                            <h3>
                                Transformation Digitale du processus de
                                traitement des domiciliations avec DomiFlow
                            </h3>

                            <p>
                                Face à des besoins croissants en matière de
                                digitalisation et d’optimisation des flux de
                                données, plusieurs banques locales ont exprimé
                                le besoin d’un système moderne et automatiséla
                                gestion du cycle de vie des domiciliations de
                                manière plus fluide et sécurisée.
                            </p>

                            <div class="overview-box">
                                <div class="overview-content">
                                    <h3>Challenge :</h3>
                                    <br />
                                    <ul>
                                        <li>
                                            Les banques locales étaient
                                            confrontées à des défis majeurs.
                                        </li>
                                        <br />
                                        <li>
                                            Gestion manuelle des flux Portnet,
                                            source de lenteurs et d’erreurs.
                                        </li>
                                        <br />
                                        <li>
                                            Conformité réglementaire complexe et
                                            nécessitant des contrôles manuels.
                                        </li>
                                        <br />
                                        <li>
                                            Manque de visibilité en temps réel
                                            sur les opérations en cours.
                                        </li>
                                        <br />
                                        <li>
                                            Intégration difficile avec le core
                                            banking existant.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="overview-box">
                                <div class="overview-content">
                                    <h3>Solution Apportée :</h3>

                                    <p>
                                        Avec la mise en œuvre de notre solution
                                        <b>DomiFlow</b>, ces banques ont pu
                                        bénéficier d’une intégration fluide et
                                        automatisée de l’ensemble de leurs flux
                                        <b>Portnet</b>
                                        grâce à :
                                    </p>
                                    <br />
                                    <ul>
                                        <li>
                                            <b
                                                >Contrôle Technique Automatique
                                                :</b
                                            >
                                            Détection et correction des
                                            anomalies avant le traitement.
                                        </li>
                                        <br />
                                        <li>
                                            <b
                                                >Automatisation des Contrôles
                                                Réglementaires :</b
                                            >
                                            Mise en œuvre de règles de gestion
                                            et interfaçage avec des systèmes
                                            tiers pour un respect total de la
                                            compliance.
                                        </li>
                                        <br />
                                        <li>
                                            <b>Efficacité Opérationnelle :</b>
                                            Adaptation aux processus internes et
                                            automatisation de l’affectation des
                                            tâches pour une meilleure
                                            répartition des ressources.
                                        </li>
                                        <br />
                                        <li>
                                            <b>Workflows BPM Personnalisés :</b>
                                            Création de workflows adaptés pour
                                            gérer les flux de manière flexible
                                            avec un traitement full automatique
                                            pour certaines opérations
                                            (annulation, imputations, etc.).
                                        </li>

                                        <br />
                                        <li>
                                            <b>Monitoring en Temps Réel :</b>
                                            Dashboards interactifs offrant une
                                            visibilité complète sur l’état des
                                            flux.
                                        </li>

                                        <br />
                                        <li>
                                            <b>Interfaçage API Flexible :</b>
                                            Intégration simplifiée avec les
                                            systèmes de la banque pour une
                                            gestion complète du cycle de vie des
                                            titres d’importation.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="overview-box">
                                <div class="overview-content">
                                    <h3>Résultats Clés :</h3>
                                    <br />
                                    <ul>
                                        <li>
                                            <b>Réduction de 40 %</b> des délais
                                            de traitement des flux Portnet.
                                        </li>
                                        <br />
                                        <li>
                                            <b>Diminution des erreurs</b> de
                                            saisie manuelle grâce aux contrôles
                                            automatisés.
                                        </li>
                                        <br />
                                        <li>
                                            <b>Conformité totale</b> avec les
                                            régulations locales et
                                            internationales.
                                        </li>
                                        <br />
                                        <li>
                                            <b
                                                >Amélioration de la
                                                Productivité</b
                                            >
                                            des équipes avec une répartition
                                            optimisée des tâches.
                                        </li>
                                        <br />
                                        <li>
                                            <b>Réduction du personnel</b>
                                            nécessaire pour ces tâches,
                                            entraînant une réduction
                                            significative des coûts
                                            opérationnels.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="overview-box">
                                <div class="overview-content">
                                    <h3>Témoignage Client :</h3>

                                    <p>
                                        <cite
                                            >“ Grâce à DomiFlow, nous avons
                                            considérablement amélioré notre
                                            efficacité opérationnelle et réduit
                                            nos délais de traitement. La
                                            flexibilité et l’automatisation
                                            proposées ont transformé notre
                                            manière de gérer les flux Portnet.
                                            ”</cite
                                        >
                                        – Directeur des Opérations d'une banque
                                        locale.
                                    </p>
                                </div>
                            </div>

                            <div class="overview-box">
                                <div class="overview-content">
                                    <h3>Conclusion :</h3>

                                    <p>
                                        La solution <b>DomiFlow</b> s’est
                                        révélée être un levier de transformation
                                        majeur pour ces banques, leur permettant
                                        d’atteindre un haut niveau de
                                        performance et de conformité, tout en
                                        optimisant leurs ressources internes. Un
                                        partenariat technologique au service de
                                        l’excellence opérationnelle.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="post-controls-buttons">
                        <div class="controls-left">
                            <a routerLink="/blog-details"><i class="icofont-double-left"></i> Prev Post</a>
                        </div>
    
                        <div class="controls-right">
                            <a routerLink="/blog-details">Next Post <i class="icofont-double-right"></i></a>
                        </div>
                    </div>
    
                    <div class="post-comments">
                        <h3>Comments</h3>
                        <div class="single-comment">
                            <div class="comment-img">
                                <img src="assets/img/client1.jpg" alt="client">
                            </div>
                            <div class="comment-content">
                                <h4>John Smith</h4>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                                <span>Jan 19, 2024 - 9:10AM</span>
                                <a routerLink="/blog-details">Reply</a>
                            </div>
                        </div>
    
                        <div class="single-comment left-m">
                            <div class="comment-img">
                                <img src="assets/img/client2.jpg" alt="client">
                            </div>
                            <div class="comment-content">
                                <h4>Doe John</h4>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                                <span>Jan 19, 2024 - 9:10AM</span>
                                <a routerLink="/blog-details">Reply</a>
                            </div>
                        </div>
    
                        <div class="single-comment">
                            <div class="comment-img">
                                <img src="assets/img/client3.jpg" alt="client">
                            </div>
                            <div class="comment-content">
                                <h4>Steven Doe</h4>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                                <span>Jan 19, 2024 - 9:10AM</span>
                                <a routerLink="/blog-details">Reply</a>
                            </div>
                        </div>
    
                        <div class="single-comment">
                            <div class="comment-img">
                                <img src="assets/img/client4.jpg" alt="client">
                            </div>
                            <div class="comment-content">
                                <h4>John Cina</h4>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                                <span>Jan 19, 2024 - 9:10AM</span>
                                <a routerLink="/blog-details">Reply</a>
                            </div>
                        </div>
                    </div>
    
                    <div class="leave-a-reply">
                        <h3>Leave a Reply</h3>
                        
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Full Name">
                                </div>
                            </div>
    
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="E-Mail">
                                </div>
                            </div>
    
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="comment" cols="30" rows="5" class="form-control" placeholder="Your Comment"></textarea>
                                </div>
                            </div>
    
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div> -->
                </div>

                <!-- <div class="col-lg-4 col-md-12">
                    <div class="sidebar-area">
                        <div class="widget widget-search">
                            <form>
                                <input type="text" class="form-control" placeholder="Search">
                                <button type="submit"><i class="icofont-search-2"></i></button>
                            </form>
                        </div>
    
                        <div class="widget widget_post_categories">
                            <h3 class="widget-title">Post Categories</h3>
                            <div class="bar"></div>
                            <ul>
                                <li><a routerLink="/blog"><i class="icofont-bubble-right"></i> Art</a></li>
                                <li><a routerLink="/blog"><i class="icofont-bubble-right"></i> Book</a></li>
                                <li><a routerLink="/blog"><i class="icofont-bubble-right"></i> Watch</a></li>
                                <li><a routerLink="/blog"><i class="icofont-bubble-right"></i> TV</a></li>
                                <li><a routerLink="/blog"><i class="icofont-bubble-right"></i> Gifts</a></li>
                                <li><a routerLink="/blog"><i class="icofont-bubble-right"></i> Design</a></li>
                                <li><a routerLink="/blog"><i class="icofont-bubble-right"></i> Smart TV</a></li>
                            </ul>
                        </div>
    
                        <div class="widget widget_recent_posts">
                            <h3 class="widget-title">Recent Post</h3>
                            <div class="bar"></div>
                            <ul>
                                <li>
                                    <div class="recent-post-thumb">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog-img1.jpg" alt="blog-image">
                                        </a>
                                    </div>
                                    <div class="recent-post-content">
                                        <h3><a routerLink="/blog-details">The Fastest Growing Apps in 2024</a></h3>
                                        <span class="date">23 January 2024</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="recent-post-thumb">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog-img2.jpg" alt="blog-image">
                                        </a>
                                    </div>
                                    <div class="recent-post-content">
                                        <h3><a routerLink="/blog-details">The Most Popular New Business Apps</a></h3>
                                        <span class="date">16 January 2024</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="recent-post-thumb">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog-img3.jpg" alt="blog-image">
                                        </a>
                                    </div>
                                    <div class="recent-post-content">
                                        <h3><a routerLink="/blog-details">3 WooCommerce Plugins to Boost Sales</a></h3>
                                        <span class="date">14 January 2024</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="recent-post-thumb">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog-img4.jpg" alt="blog-image">
                                        </a>
                                    </div>
                                    <div class="recent-post-content">
                                        <h3><a routerLink="/blog-details">CakeMail Review – Design Custom Emails</a></h3>
                                        <span class="date">06 January 2024</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
    
                        <div class="widget widget_tag_cloud">
                            <h3 class="widget-title">Popular Tags</h3>
                            <div class="bar"></div>
                            <div class="tagcloud">
                                <a routerLink="/blog">Art</a>
                                <a routerLink="/blog">Book</a>
                                <a routerLink="/blog">Watch</a>
                                <a routerLink="/blog">TV</a>
                                <a routerLink="/blog">Gifts</a>
                                <a routerLink="/blog">Smart TV</a>
                                <a routerLink="/blog">Design</a>
                            </div>
                        </div>
    
                        <div class="widget widget_text">
                            <h3 class="widget-title">Instagram</h3>
                            <div class="bar"></div>
                            <ul>
                                <li><a href="#" target="_blank"><img src="assets/img/work-img1.jpg" alt="image"></a></li>
                                <li><a href="#" target="_blank"><img src="assets/img/work-img2.jpg" alt="image"></a></li>
                                <li><a href="#" target="_blank"><img src="assets/img/work-img3.jpg" alt="image"></a></li>
                                <li><a href="#" target="_blank"><img src="assets/img/work-img4.jpg" alt="image"></a></li>
                                <li><a href="#" target="_blank"><img src="assets/img/work-img5.jpg" alt="image"></a></li>
                                <li><a href="#" target="_blank"><img src="assets/img/work-img6.jpg" alt="image"></a></li>
                            </ul>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
</div>

<div *ngIf="id == 'pain001'">
    <section class="blog-details-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="blog-details">
                        <div class="article-img">
                            <div style="display: flex; justify-content: center">
                                <img
                                    src="assets/img/iso20022-small.png"
                                    alt="blog-details"
                                />
                            </div>
                            <div class="date">
                                12 <br />
                                2022
                            </div>
                        </div>

                        <div class="article-content">
                            <!-- <ul class="category">
                                <li><a routerLink="/blog">IT</a></li>
                                <li><a routerLink="/blog">Mobile</a></li>
                                <li><a routerLink="/blog">Marketing</a></li>
                                <li><a routerLink="/blog">Design</a></li>
                                <li><a routerLink="/blog">Development</a></li>
                            </ul> -->
                            <h3>Pain001 Core Banking Integration</h3>

                            <p>
                                Notre récente réussite dans la mise en œuvre de
                                la génération automatisée des messages PAIN.001
                                et leur intégration fluide avec le système
                                bancaire central interne a révolutionné nos
                                opérations de transfert du middle office vers le
                                backend. En développant un middleware robuste
                                capable de générer automatiquement des messages
                                PAIN.001 XML conformes, nous avons
                                considérablement réduit les erreurs de saisie
                                manuelle et augmenté l'efficacité
                                opérationnelle. La solution respecte les normes
                                ISO 20022, garantissant la compatibilité et la
                                conformité réglementaire sur toutes les
                                opérations de paiement. <br />
                                <br />

                                La principale réalisation réside dans
                                l'automatisation complète du processus de
                                transfert du middle office vers le backend. Une
                                fois qu'une demande de transfert est initiée au
                                middle office, notre solution génère
                                dynamiquement un message PAIN.001, le valide
                                pour son exactitude technique et sémantique, et
                                l'achemine de manière sécurisée vers le système
                                bancaire central pour une exécution directe.
                                Cette approche rationalisée a non seulement
                                accéléré le traitement des transactions, mais
                                aussi renforcé la traçabilité et le contrôle des
                                opérations de transfert, minimisant ainsi les
                                retards et les risques de traitement. <br />
                                <br />

                                Un exemple marquant de ce succès est notre
                                collaboration avec une banque internationale
                                opérant localement, qui a entièrement adopté
                                notre solution pour simplifier ses opérations de
                                transfert. De plus, l'intégration réussie avec
                                le système bancaire central a créé un cadre
                                évolutif pour de futures améliorations, telles
                                que l'ajout de nouveaux types de messages de
                                paiement et de nouvelles couches
                                d'automatisation. L'expertise de notre équipe
                                dans les formats de messages SWIFT et la
                                connectivité bancaire centrale a assuré une
                                transition fluide avec un minimum de
                                perturbations des opérations en cours. Cette
                                étape clé réaffirme notre engagement à fournir
                                des solutions technologiques financières
                                innovantes, favorisant l'efficacité, la
                                conformité et l'excellence opérationnelle.
                            </p>

                            <!-- <div class="overview-box">
                                <div class="overview-content">
                                    <h3>Challenge :</h3>
                                    <br />
                                    <ul>
                                        <li>
                                            Gestion manuelle des contrôles de
                                            conformité.
                                        </li>
                                        <br />
                                        <li>
                                            Difficulté à assurer une
                                            surveillance efficace des sanctions
                                            et embargos.
                                        </li>
                                        <br />
                                        <li>
                                            Manque de traçabilité et
                                            sécurisation des pistes d’audit.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="overview-box">
                                <div class="overview-content">
                                    <h3>Solution Apportée :</h3>

                                    <p>
                                        Avec la mise en œuvre de notre solution
                                        <b>Compliflow</b>, la <b>SGMA</b> ,<b
                                            >DAR AL AMANE</b
                                        >
                                        et la <b>SGOS</b> ont pu automatiser et
                                        centraliser l’ensemble de leurs
                                        contrôles de conformité.
                                    </p>
                                </div>
                            </div>

                            <div class="overview-box">
                                <div class="overview-content">
                                    <h3>Résultats Clés :</h3>
                                    <br />
                                    <ul>
                                        <li>
                                            <b>Réduction de 40 %</b> des erreurs
                                            de conformité.
                                        </li>
                                        <br />
                                        <li>
                                            <b>Gain de 30 %</b> de temps sur les
                                            processus de contrôle.
                                        </li>
                                        <br />
                                        <li>
                                            <b
                                                >Amélioration de la
                                                Traçabilité</b
                                            >
                                            grâce à l'automatisation des pistes
                                            d'audit.
                                        </li>
                                        <br />
                                        <li>
                                            <b>Conformité Renforcée</b> avec une
                                            surveillance proactive et continue.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="overview-box">
                                <div class="overview-content">
                                    <h3>Témoignage Client :</h3>

                                    <p>
                                        <cite
                                            >“ Compliflow nous a permis
                                            d’automatiser entièrement nos
                                            processus de conformité, nous
                                            assurant une surveillance continue
                                            et une tranquillité d’esprit totale.
                                            ”</cite
                                        >
                                        – DP GTPS SGMA.
                                    </p>
                                </div>
                            </div>

                            <div class="overview-box">
                                <div class="overview-content">
                                    <h3>Conclusion :</h3>

                                    <p>
                                        La solution <b>Compliflow</b> a permis à
                                        ces banques d’optimiser
                                        significativement leurs processus de
                                        conformité, garantissant à la fois un
                                        gain d’efficacité et une meilleure
                                        gestion des risques réglementaires et
                                        conformité , évitant ainsi les sanctions
                                        et les fraudes .
                                    </p>
                                </div>
                            </div> -->
                        </div>
                    </div>

                    <!-- <div class="post-controls-buttons">
                        <div class="controls-left">
                            <a routerLink="/blog-details"><i class="icofont-double-left"></i> Prev Post</a>
                        </div>
    
                        <div class="controls-right">
                            <a routerLink="/blog-details">Next Post <i class="icofont-double-right"></i></a>
                        </div>
                    </div>
    
                    <div class="post-comments">
                        <h3>Comments</h3>
                        <div class="single-comment">
                            <div class="comment-img">
                                <img src="assets/img/client1.jpg" alt="client">
                            </div>
                            <div class="comment-content">
                                <h4>John Smith</h4>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                                <span>Jan 19, 2024 - 9:10AM</span>
                                <a routerLink="/blog-details">Reply</a>
                            </div>
                        </div>
    
                        <div class="single-comment left-m">
                            <div class="comment-img">
                                <img src="assets/img/client2.jpg" alt="client">
                            </div>
                            <div class="comment-content">
                                <h4>Doe John</h4>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                                <span>Jan 19, 2024 - 9:10AM</span>
                                <a routerLink="/blog-details">Reply</a>
                            </div>
                        </div>
    
                        <div class="single-comment">
                            <div class="comment-img">
                                <img src="assets/img/client3.jpg" alt="client">
                            </div>
                            <div class="comment-content">
                                <h4>Steven Doe</h4>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                                <span>Jan 19, 2024 - 9:10AM</span>
                                <a routerLink="/blog-details">Reply</a>
                            </div>
                        </div>
    
                        <div class="single-comment">
                            <div class="comment-img">
                                <img src="assets/img/client4.jpg" alt="client">
                            </div>
                            <div class="comment-content">
                                <h4>John Cina</h4>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                                <span>Jan 19, 2024 - 9:10AM</span>
                                <a routerLink="/blog-details">Reply</a>
                            </div>
                        </div>
                    </div>
    
                    <div class="leave-a-reply">
                        <h3>Leave a Reply</h3>
                        
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Full Name">
                                </div>
                            </div>
    
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="E-Mail">
                                </div>
                            </div>
    
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="comment" cols="30" rows="5" class="form-control" placeholder="Your Comment"></textarea>
                                </div>
                            </div>
    
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div> -->
                </div>

                <!-- <div class="col-lg-4 col-md-12">
                    <div class="sidebar-area">
                        <div class="widget widget-search">
                            <form>
                                <input type="text" class="form-control" placeholder="Search">
                                <button type="submit"><i class="icofont-search-2"></i></button>
                            </form>
                        </div>
    
                        <div class="widget widget_post_categories">
                            <h3 class="widget-title">Post Categories</h3>
                            <div class="bar"></div>
                            <ul>
                                <li><a routerLink="/blog"><i class="icofont-bubble-right"></i> Art</a></li>
                                <li><a routerLink="/blog"><i class="icofont-bubble-right"></i> Book</a></li>
                                <li><a routerLink="/blog"><i class="icofont-bubble-right"></i> Watch</a></li>
                                <li><a routerLink="/blog"><i class="icofont-bubble-right"></i> TV</a></li>
                                <li><a routerLink="/blog"><i class="icofont-bubble-right"></i> Gifts</a></li>
                                <li><a routerLink="/blog"><i class="icofont-bubble-right"></i> Design</a></li>
                                <li><a routerLink="/blog"><i class="icofont-bubble-right"></i> Smart TV</a></li>
                            </ul>
                        </div>
    
                        <div class="widget widget_recent_posts">
                            <h3 class="widget-title">Recent Post</h3>
                            <div class="bar"></div>
                            <ul>
                                <li>
                                    <div class="recent-post-thumb">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog-img1.jpg" alt="blog-image">
                                        </a>
                                    </div>
                                    <div class="recent-post-content">
                                        <h3><a routerLink="/blog-details">The Fastest Growing Apps in 2024</a></h3>
                                        <span class="date">23 January 2024</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="recent-post-thumb">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog-img2.jpg" alt="blog-image">
                                        </a>
                                    </div>
                                    <div class="recent-post-content">
                                        <h3><a routerLink="/blog-details">The Most Popular New Business Apps</a></h3>
                                        <span class="date">16 January 2024</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="recent-post-thumb">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog-img3.jpg" alt="blog-image">
                                        </a>
                                    </div>
                                    <div class="recent-post-content">
                                        <h3><a routerLink="/blog-details">3 WooCommerce Plugins to Boost Sales</a></h3>
                                        <span class="date">14 January 2024</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="recent-post-thumb">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog-img4.jpg" alt="blog-image">
                                        </a>
                                    </div>
                                    <div class="recent-post-content">
                                        <h3><a routerLink="/blog-details">CakeMail Review – Design Custom Emails</a></h3>
                                        <span class="date">06 January 2024</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
    
                        <div class="widget widget_tag_cloud">
                            <h3 class="widget-title">Popular Tags</h3>
                            <div class="bar"></div>
                            <div class="tagcloud">
                                <a routerLink="/blog">Art</a>
                                <a routerLink="/blog">Book</a>
                                <a routerLink="/blog">Watch</a>
                                <a routerLink="/blog">TV</a>
                                <a routerLink="/blog">Gifts</a>
                                <a routerLink="/blog">Smart TV</a>
                                <a routerLink="/blog">Design</a>
                            </div>
                        </div>
    
                        <div class="widget widget_text">
                            <h3 class="widget-title">Instagram</h3>
                            <div class="bar"></div>
                            <ul>
                                <li><a href="#" target="_blank"><img src="assets/img/work-img1.jpg" alt="image"></a></li>
                                <li><a href="#" target="_blank"><img src="assets/img/work-img2.jpg" alt="image"></a></li>
                                <li><a href="#" target="_blank"><img src="assets/img/work-img3.jpg" alt="image"></a></li>
                                <li><a href="#" target="_blank"><img src="assets/img/work-img4.jpg" alt="image"></a></li>
                                <li><a href="#" target="_blank"><img src="assets/img/work-img5.jpg" alt="image"></a></li>
                                <li><a href="#" target="_blank"><img src="assets/img/work-img6.jpg" alt="image"></a></li>
                            </ul>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
</div>
<!-- End Blog Details Area -->
