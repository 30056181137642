import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Component({
    selector: "app-SwiftBridge-landing",
    templateUrl: "./Compliance.component.html",
    styleUrls: ["./Compliance.component.scss"],
})
export class ComplianceComponent implements OnInit {
    constructor(private titleService: Title, private meta: Meta) {
        this.titleService.setTitle("Agilisys Maroc - COMPLIANCE");

        this.meta.updateTag({
            name: "description",
            content:
                "Centralise et automatise vos contrôles Compliance tout en apportant une attention particulière au volet Sanctions et embargos, assure un gain en efficacité en vous permettant de vous recentrer sur des tâches à forte valeur ajoutée, et garantit une sécurisation des contrôles à travers l’automatisation des pistes d’audit",
        });

        this.meta.updateTag({
            name: "keywords",
            content:
                "Contrôles Compliance, Sanctions et embargos, Automatisation des contrôles Compliance, Gestion des risques, Efficacité opérationnelle, Gestion des tâches à forte valeur ajoutée, Sécurisation des contrôles, Automatisation des pistes d’audit",
        });
    }

    ngOnInit() {}
}
