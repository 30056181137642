import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Component({
    selector: "app-SwiftBridge-landing",
    templateUrl: "./SwiftBridge.component.html",
    styleUrls: ["./SwiftBridge.component.scss"],
})
export class SwiftBridgeComponent implements OnInit {
    constructor(private titleService: Title, private meta: Meta) {
        this.titleService.setTitle("Agilisys Maroc - Swift Bridge");
        this.meta.updateTag({
            name: "description",
            content:
                "Simplifie, automatise la gestion de vos flux de paiements en format MT et MX, assure une conversion fluide et paramétrable entre le format MT et MX avec une validation multicouches garantissant ainsi la sécurité, l’intégrité et la conformité de vos transactions Financières.",
        });

        this.meta.updateTag({
            name: "keywords",
            content:
                "Simplifie la gestion des flux de paiements, Automatise la gestion des flux de paiements, Flux de paiements en format MT, Flux de paiements en format MX, Conversion fluide entre format MT et MX, Conversion paramétrable entre format MT et MX, Validation multicouches, Sécurité des transactions financières, Intégrité des transactions financières, Conformité des transactions financières, Gestion des transactions financières, Paiements MT et MX, Automatisation des paiements, Transactions financières sécurisées",
        });
    }

    ngOnInit() {}
}
